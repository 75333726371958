@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  body {
    @apply leading-snug text-blueDianne;
    font-size: 2.5rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  h2 {
    @apply text-[2.25rem] font-medium leading-[1.155] lg:text-[3.5rem] xl:text-[4.5rem] 2xl:text-[5.625rem];
  }
  @media (min-width: 768px) {
    body {
      font-size: 1.5rem;
    }
  }
  @media (min-width: 1024px) {
    body {
      font-size: 2rem;
    }
  }
  @media (min-width: 1280px) {
    body {
      font-size: 2.25rem;
    }
  }
  @media (min-width: 1536px) {
    body {
      font-size: 2.5rem;
    }
  }
  .richtext p {
    margin-bottom: 3rem;
  }

  .richtext li {
    list-style: disc;
  }
}

@media (min-width: 1024px) {
  .hero-gradient {
    background-image: linear-gradient(
      90deg,
      #ffffff 25%,
      #31c1b7 25%,
      #31c1b7 100%
    );
  }
}
